// @flow

import React, { createContext, useContext, useReducer } from 'react'

export const StateContext = createContext<Object>()

export const State = ({ reducer, initialState, children }: 
{ reducer: Object, initialState: Object, children: React$Node }) =>
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>

export const useGlobalState = () => useContext(StateContext)
