// @flow

const initialState = {
  receiving: false,
  done: false,
}

const reducer = (state: Object, action: Object): Object => {
  const { data, type }: { data: Object, type: string } = action
  switch (type) {
    case 'stdout-end':
      return {
        receiving: false,
        done: true,
        ...data,
      }
    case 'stdout-start':
      return {
        receiving: true,
        done: false,
        ...data,
      }
    default:
      return state
  }
}

export { reducer as stdoutReducer, initialState as stdout }
