import React, { lazy, Suspense } from 'react'
import Loader from 'components/Loader'
import { session } from 'store/reducers/session.reducer'
import { event } from 'store/reducers/event.reducer'
import { stdout } from 'store/reducers/stdout.reducer'
import { State } from 'store/GlobalState'
import reducer from 'store'
const Layout = lazy(() => import(/* webpackChunkName: "Layout" */ './Layout'))

const initialState = {
  session,
  event,
  stdout
}

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <State initialState={initialState} reducer={reducer}>
        <Layout />
      </State>
    </Suspense>
  )
}

export default App