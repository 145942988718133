// @flow

const initialState = {
  role: null,
  loggedIn: false,
  name: null
}

const reducer = (state: Object, action: Object): Object => {
  const { payload, type }: { payload: Object, type: string } = action
  switch (type) {
    case 'initSuccess':
      return {
        ...payload
      }
    case 'initFailure':
      return {
        ...initialState
      }
    case 'login':
      return {
        ...state,
        loggedIn: true
      }
    default:
      return state
  }
}

export { reducer as sessionReducer, initialState as session }
