import { sessionReducer } from 'store/reducers/session.reducer'
import { eventReducer } from 'store/reducers/event.reducer'
import { stdoutReducer } from 'store/reducers/stdout.reducer'


const reducer = ({ session, event, stdout }, action) => {
  const state = {
    session: sessionReducer(session, action),
    event: eventReducer(event, action),
    stdout: stdoutReducer(stdout, action)
  }

  return state
}

export default reducer
