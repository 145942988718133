// @flow

const initialState = {
  event: null
}

const reducer = (state: Object, action: Object): Object => {
  const { payload, type }: { payload: Object, type: string } = action
  switch (type) {
    // case 'login':
    //   return {
    //     loggedIn: payload
    //   }
    case 'eventTrigger':
      return payload
    case 'error':
      return {
        ...state,
        ...payload,
        alert: null,
        error: payload.error,
        onClose: payload.onClose,
        onCancel: payload.onCancel,
      }
    case 'alert':
      return {
        ...state,
        ...payload,
        error: null,
        alert: payload.alert,
        onClose: payload.onClose,
        onCancel: payload.onCancel
      }
    default:
      return state
  }
}

export { reducer as eventReducer, initialState as event }
